import Images from "../Styles/Images";

export default {
  AppName: 'Qdev Techs',
  APIServer: 'https://jsonplaceholder.typicode.com',
  projectsData: [
    {
      name: 'JustFans',
      slug:'justfans',
      category: "PHP Scripts",
      slogan: "Premium Content Creators SaaS platform",
      description: "JustFans – Premium Content Creators SaaS is a fully-featured PHP platform that allows you to start your own premium content-based social media platform in no time.",
      imageSrc: Images.fans_preview,
      regularPrice: 69,
      extendedPrice: 199,
      rating: 5,
      order: 1,
      envatoUrl: "https://codecanyon.net/item/justfans-premium-content-creators-saas-platform/35154898",
      demoUrl: "https://justfans.qdev.tech/",
      documentationUrl: "https://docs.qdev.tech/justfans/",
      changelogUrl: "https://changelogs.qdev.tech/products/fans",
      version: "7.8.0",
      lastUpdateDate: "Jan 22, 2025"
    },
    {
      name: 'JustJobs',
      slug:'justjobs',
      category: "PHP Scripts",
      slogan: "Remote Job Listings SaaS platform",
      description: "JustJobs – Remote Job Listings SaaS platform is a fully-featured PHP platform that allows you to start your own subscription-based job listing website in no time.",
      imageSrc: Images.jobs_preview,
      regularPrice: 29,
      extendedPrice: 99,
      rating: 5,
      order: 2,
      envatoUrl: "https://codecanyon.net/item/justjobs-remote-job-listings-saas-platform/49643217",
      demoUrl: "https://justjobs.qdev.tech/",
      documentationUrl: "https://docs.qdev.tech/justjobs/",
      changelogUrl: "https://changelogs.qdev.tech/products/jobs",
      version: "2.0.0",
      lastUpdateDate: "Jan 22, 2025"
    }
  ]
};
